import { Controller } from "stimulus"
import { CartEventBus } from "./events"

export default class extends Controller {
  static targets = ["items", "footer"]

  connect() {
    CartEventBus.on("addItem", (event) => this.onAddItem(event))
    CartEventBus.on("updateItem", (event) => this.onUpdateItem(event))
    CartEventBus.on("removeItem", (event) => this.onRemoveItem(event))
  }

  toggle() {
    this.element.classList.toggle(this.data.get("openClass"))
  }

  onAddItem(event) {
  }

  onUpdateItem(event) {
  }

  onRemoveItem(event) {
    
  }

  get isOpen() {
    this.element.classList.contains(this.data.get("openClass"))
  }

  get isClosed() {
    !this.isOpen
  }

  get cartItemsController() {
    if (this.hasItemsTarget) {
      return this.application.getControllerForElementAndIdentifier(this.itemsTarget, "cart-items")
    }
  }

  get cartFooterController() {
    if (this.hasFooterTarget) {
      return this.application.getControllerForElementAndIdentifier(this.footerTarget, "cart-footer")
    }
  }
}
