import "@asgerb/apparatus"
import "@asgerb/apparatus/package/dist/index.css"

import "@asgerb/apparatus_ui"

import "@asgerb/apparatus-shop"
import "@asgerb/apparatus-shop/package/dist/index.css"

import "base"

import "blocks/base"
import "blocks/button_block"
import "blocks/cart_block"
import "blocks/column_block"
import "blocks/event_list_block"
import "blocks/file_block"
import "blocks/header_block"
import "blocks/image_block"
import "blocks/images_block"
import "blocks/locale_changer_block"
import "blocks/page_menu_block"
import "blocks/product_list_block"
import "blocks/splash_block"
import "blocks/text_block"
import "blocks/video_block"
import "blocks/popup_block"

import "models/cart"
import "models/cart_item"
import "models/checkout"
import "models/product"

import "resources/event"

import "inputs/variant_selector_input"
import "inputs/quantity_input"

import "components/apparatus/shop/cart_item/summary_component"
import "components/apparatus/shop/cart/button_component"
import "components/apparatus/shop/cart/checkout_button_component"
import "components/apparatus/shop/checkout/breadcrumb_component"
import "components/apparatus/shop/checkout/breadcrumbs_component"
import "components/apparatus/shop/order/confirmation_component"
import "components/apparatus/shop/stripe/card_component"
import "components/product/add_to_cart_component"

import "forms/address_form"

import "checkout_steps/customer_information_step"
import "checkout_steps/payment_method_step"
import "checkout_steps/checkout_step"

import "master_pages/index"
import "master_pages/page"

require.context('../images', true)
require.context('../fonts', true)

import Modernizr from "modernizr"

require("@rails/ujs").start()

import Turbolinks from "turbolinks"
Turbolinks.start()

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const controllers = require.context("../controllers", true, /\.js$/)

application.load(definitionsFromContext(controllers))
