import { Controller } from "stimulus"
import { CartEventBus } from "./events"

export default class extends Controller {
  static targets = ["submit"]

  initialize() {
    this.sellableSelect.addEventListener("change", (event) => {
      this.setSubmitButtonState()
    })
  }

  connect() {
    this.setSubmitButtonState()
  }

  onSuccess(event) {
    CartEventBus.emit("addItem", event)
  }

  onError(event) {}

  setSubmitButtonState() {
    if (this.selectedSellable) {
      this.submitTarget.disabled = this.selectedSellable.disabled
    }
  }

  get selectedSellable() {
    return this.sellableSelect.options[this.sellableSelect.selectedIndex]
  }

  get sellableSelect() {
    return this.element.querySelector(".variant_selector_input__sellable_select")
  }

  get variantSelectInput() {
    return this.element.querySelector(".variant_selector_input")
  }

  get cartDrawerController() {
    return this.getControllerByIdentifier("cart-drawer")
  }

  get cartButtonController() {
    return this.getControllerByIdentifier("cart-button")
  }

  get variantSelectorInputController() {
    return this.getControllerByIdentifier("variant-selector-input")
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier
    })
  }
}
