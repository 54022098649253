import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
  }

  onAddItem(event) {
    let [data, status, xhr] = event.detail
    this.incrementItemCount()
  }

  onUpdateItem() {

  }

  onDestroyItem() {

  }

  incrementItemCount() {

  }
}
