import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["closeButton"]

  connect() {
  }

  close() {
    this.element.classList.add("popup_block--closed")
  }
}
