import { Controller } from "stimulus"
import SimpleFormMarkdownEditor from "@tomasc/simple_form_markdown_editor"

export default class extends Controller {
  connect() {
    this.simpleFormMarkdownEditor = new SimpleFormMarkdownEditor(this.element)
  }

  disconnect() {
    this.simpleFormMarkdownEditor.destroy()
  }
}
