import { Controller } from "stimulus"
import { CheckoutEventBus } from "./events"
import * as currency from "currency.js"

export default class extends Controller {
  static targets = ["deliveryPrice", "subtotal", "total"]

  connect() {
    CheckoutEventBus.on("updatedDeliveryCountry", (country) => this.updateDeliveryPrice(country))
  }

  updateDeliveryPrice(country) {
    this._fetchDeliveryPrice(country)
      .then(response => response.text())
      .then(deliveryPrice => {
        this.deliveryPrice = deliveryPrice
        this.updateTotal()
      })
  }

  updateTotal() {
    let total = currency(this.subtotal).add(this.deliveryPrice)
    this.total = total.format({symbol: "€", formatWithSymbol: true}).replace(/\.00/, "")
  }

  get subtotal() {
    return this.subtotalTarget.textContent
  }

  set subtotal(subtotal) {
    this.subtotalTarget.innerHTML = subtotal
  }

  get total() {
    return this.totalTarget.textContent
  }

  set total(total) {
    this.totalTarget.innerHTML = total
  }

  get deliveryPrice() {
    return this.deliveryPriceTarget.textContent
  }

  set deliveryPrice(deliveryPrice) {
    this.deliveryPriceTarget.innerHTML = deliveryPrice
  }

  _fetchDeliveryPrice(country) {
    return fetch(`${this.data.get('delivery-price-url')}?country=${country}`)
  }
}
