import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["letter"]

  connect() {
    const event = document.createEvent("Events")
    event.initEvent("shadow-letters-connected", true, true)
    this.element.dispatchEvent(event);



    // this.letterTargets.forEach(letter => {
    //   let width = letter.offsetWidth
    //   let height = this.shadowHeight
    //   let position = letter.offsetLeft + (width / 2)
    //   this.splashBlockController.drawShadow(width, height, position)
    // })
  }

  get shadowHeight() {
    return this.maxLetterWidth + this.shadowPadding
  }

  get maxLetterWidth() {
    return this.widestLetter.offsetWidth
  }

  get widestLetter() {
    let maxWidth = 0
    let widestLetter = null

    this.letterTargets.forEach(letter => {
      if (letter.offsetWidth > maxWidth) {
        maxWidth = letter.offsetWidth
        widestLetter = letter
      }
    })

    return widestLetter
  }

  get splashBlockController() {
    return this.application.getControllerForElementAndIdentifier(this.splashBlock, "splash-block")
  }

  get splashBlock() {
    return this.element.closest(".block.splash_block")
  }

  get shadowPadding() {
    return 10
  }
}
