import { Controller } from "stimulus"
const FontFaceObserver = require('fontfaceobserver')

export default class extends Controller {
  static targets = ["shadowLetters", "shadows", "title"]

  connect() {
    const shadowPromise = this.shadowConnectedPromise
    const shadowLettersPromise = this.shadowLettersConnected
    const ginto = new FontFaceObserver("Ginto").load()

    Promise.all([shadowPromise, shadowLettersPromise, ginto]).then(res =>{
      this.drawShadows()
    })
  }

  drawShadows() {
    this.shadowLettersController.letterTargets.forEach(letter => {
      let width = letter.offsetWidth
      let height = this.shadowLettersController.shadowHeight
      let position = letter.offsetLeft + (width / 2)
      this.shadowsController.drawShadow(width, height, position)
    })

    this.setShadowsPosition()
    this.shadowsController.animateShadows()
  }

  updateShadows() {
    this.shadowLettersController.letterTargets.forEach((letter, index) => {
      let width = letter.offsetWidth
      let height = this.shadowLettersController.shadowHeight
      let position = letter.offsetLeft + (width / 2)
      this.shadowsController.updateShadow(index, width, height, position)
    })
  }

  setShadowsPosition() {
    this.shadowsController.setPosition(this.shadowsPosition[0], this.shadowsPosition[1])
  }

  get shadowsPosition() {
    let x = this.titleTarget.offsetLeft - (this.titleTarget.offsetWidth / 2)
    let y = this.titleTarget.offsetTop + (this.titleTarget.offsetHeight / 2)
    return [x, y]
  }

  get shadowConnectedPromise() {
    return new Promise((resolve, reject) => {
      document.addEventListener("shadow-connected", (event) => { resolve() })
    })
  }

  get shadowLettersConnected() {
    return new Promise((resolve, reject) => {
      document.addEventListener("shadow-letters-connected", (event) => { resolve() })
    })
  }

  get maxLetterWidth() {
    return this.shadowLettersController.maxLetterWidth
  }

  get shadowLettersController() {
    return this.application.getControllerForElementAndIdentifier(this.shadowLettersTarget, "splash-block-shadow-letters")
  }

  get shadowsController() {
    return this.application.getControllerForElementAndIdentifier(this.shadowsTarget, "splash-block-shadows")
  }
}
