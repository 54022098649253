import _debounce from "lodash/debounce"
import { Controller } from "stimulus"
import { CartEventBus } from "./events"

export default class extends Controller {
  static targets = ["quantity"]

  initialize() {
    this.updateQuantity = _debounce(this.updateQuantity, 200)

    CartEventBus.on("addItem", (event) => this.onAddItem(event) )
    CartEventBus.on("updateItem", (event) => this.onUpdateItem(event) )
    CartEventBus.on("removeItem", (event) => this.onRemoveItem(event) )
  }

  connect() {
    this.updateQuantity
  }

  onAddItem(event) {
    this.updateQuantity()
  }

  onUpdateItem(event) {
    this.updateQuantity()
  }

  onRemoveItem(event) {
    this.updateQuantity()
  }

  openCart() {
    if (this.cartDrawerController) {
      this.cartDrawerController.toggle()
    }
  }

  incrementQuantity() {
    if (this.hasQuantityTarget) {
      this.quantityTarget.textContent++
    }
  }

  decrementQuantity() {
    if (this.hasQuantityTarget) {
      this.quantityTarget.textContent--
    }
  }

  updateQuantity() {
    fetch(this.data.get("quantity-url"))
      .then(response => response.text())
      .then(html => {
        this.quantity = html
        this.setEmptyState()
      })
  }

  setEmptyState() {
    if (this.quantity == 0) {
      this.element.classList.add(this.data.get("emptyClass"))
    } else {
      this.element.classList.remove(this.data.get("emptyClass"))
    }
  }

  get quantity() {
    return this.quantityTarget.textContent
  }

  set quantity(newQuantity) {
    this.quantityTarget.textContent = newQuantity
  }

  get cartDrawerController() {
    return this.getControllerByIdentifier("cart-drawer")
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier
    })
  }
}
