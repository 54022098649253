import { Controller } from "stimulus"
import { SVG } from "@svgdotjs/svg.js"

export default class extends Controller {
  connect() {
  }

  update(width, height, position) {
    SVG(this.element).attr({ rx: width / 2, ry: height / 2, cy: height / 2, cx: position + 1 })
  }
}
