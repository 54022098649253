import { Controller } from "stimulus"
import { CartEventBus } from "./events"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    CartEventBus.on("addItem", (event) => this.onAddItem(event) )
    CartEventBus.on("removeItem", (event) => this.onRemoveItem(event) )
  }

  onAddItem(event) {
    this.inputTarget.disabled = false
  }

  onRemoveItem(event) {
    this.setDisabledState()
  }

  setDisabledState() {
    if (this.cartItemsController) {
      this.inputTarget.disabled = !this.cartItemsController.hasItemTarget
    }
  }

  get cartItemsController() {
    return this.getControllerForIdentifier("cart-items")
  }

  getControllerForIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier
    })
  }
}
