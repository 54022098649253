import { Controller } from "stimulus"
import { CheckoutEventBus } from "./events"

export default class extends Controller {
  static targets = ["country", "hasDeliveryAddress"]

  onChangeCountry(event) {
    CheckoutEventBus.emit("updatedDeliveryCountry", this.deliveryCountry)
  }

  get deliveryCountry() {
    return this.countryTargets.slice(-1)[0].value
  }
}
