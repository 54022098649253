import { Controller } from "stimulus"
import { CartEventBus } from "./events"
import _debounce from "lodash/debounce"

export default class extends Controller {
  static targets = ["subtotal"]

  connect() {
    this.updateSubtotal = _debounce(this.updateSubtotal, 300)

    CartEventBus.on("addItem", (event) => this.onAddItem(event) )
    CartEventBus.on("updateItem", (event) => this.onUpdateItem(event) )
    CartEventBus.on("removeItem", (event) => this.onRemoveItem(event) )
  }

  onAddItem(event) {
    this.updateSubtotal()
  }

  onUpdateItem(event) {
    this.updateSubtotal()
  }

  onRemoveItem(event) {
    this.updateSubtotal()
  }

  updateSubtotal() {
    fetch(this.data.get("subtotal-url"))
      .then(response => response.text())
      .then(subtotal => {
        this.subtotalTarget.innerHTML = subtotal
      })
  }
}
