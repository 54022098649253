import { CardComponent } from "@asgerb/apparatus-shop"
import { CheckoutEventBus } from "./events"

export default class extends CardComponent {
  connect() {
    CheckoutEventBus.on("submittedPaymentMethodForm", (event) => this.handlePayment())

    this.elementsConfig = {
      ...this.elementsConfig,
      fonts: [
        {
          src: `url(${this.fontPath}) format("woff")`,
          family: "Cormorant",
          style: "normal"
        }
      ]
    }

    this.cardElementConfig = {
      ...this.cardElementConfig,
      style: {
        base: {
          color: '#000000',
          fontSize: '22px',
          lineHeight: '24px',
          fontFamily: '"Cormorant", serif',
          fontSmoothing: 'antialiased'
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      }
    }

    super.connect()
  }

  onPaymentSuccess(result) {
    super.onPaymentSuccess(result)
    CheckoutEventBus.emit("handlePaymentSucces", result)
  }

  onPaymentError(result) {
    super.onPaymentError(result)
    CheckoutEventBus.emit("handlePaymentError", result)
  }

  get fontPath() {
    return this.data.get("fontPath")
  }
}
