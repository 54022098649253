import morphdom from "morphdom"
// import Rails from "rails-ujs"
import _debounce from "lodash/debounce"
import { Controller } from "stimulus"
import { CartEventBus } from "./events"

export default class extends Controller {
  static targets = ["quantityForm", "price", "total", "removeButton"]

  initialize() {
    this.submitQuantityForm = _debounce(this.submitQuantityForm, 300)
  }

  remove(event) {
    this.element.remove()
    CartEventBus.emit("removeItem", event)
  }

  changeQuantity() {
    this.loading = true
    this.submitQuantityForm()
  }

  submitQuantityForm() {
    if (this.hasQuantityFormTarget) {
      Rails.fire(this.quantityFormTarget, "submit")
    }
  }

  onUpdateSuccess(event) {
    this.loading = false
    let [data, status, xhr] = event.detail
    morphdom(this.element, xhr.responseText)
    CartEventBus.emit("updateItem", event)
  }

  onUpdateError(event) {
    let [data, status, xhr] = event.detail
  }

  onDestroySuccess(event) {
    let [data, status, xhr] = event.detail
  }

  onDestroyError(event) {
    let [data, status, xhr] = event.detail
  }

  get cartButtonController() {
    return this.getControllerByIdentifier("cart-button")
  }

  get cartDrawerController() {
    return this.getControllerByIdentifier("cart-drawer")
  }

  get error() {
    return this.element.querySelector(".error")
  }

  set loading(loading) {
    this.element.classList.toggle(this.data.get("loadingClass"), loading)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier
    })
  }
}
