import { Controller } from "stimulus"
import { SVG } from "@svgdotjs/svg.js"
import { TimelineMax } from "gsap/TweenMax"
import * as SunCalc from "suncalc"

export default class extends Controller {
  static targets = ["svg", "clipPath", "image", "openImage", "closedImage", "shadow"]

  connect() {
    const event = document.createEvent("Events")
    event.initEvent("shadow-connected", true, true)
    this.element.dispatchEvent(event)
    this.setShadowImage()
    window.setInterval(this.setShadowImage(), 3000)
  }

  drawShadow(width, height, position) {
    let ellipse = this.svg.ellipse(width, height).attr({ cy: height / 2, cx: position + 1 }).data({ target: "splash-block-shadows.shadow", controller: "shadow" })
    this.clipPath.add(ellipse)
  }

  updateShadow(index, width, height, position) {
    let ellipse = this.clipPathTarget.querySelectorAll("ellipse")[index]
    let shadowController = this.application.getControllerForElementAndIdentifier(ellipse, "shadow")
    if (shadowController) {
      shadowController.update(width, height, position)
    }
  }

  animateShadows() {
    let timeline = new TimelineMax
    timeline.pause()

    let shadows = this._buildShadowsAnimation()

    let index = 0
    while (index < shadows.length) {
      let shadow = shadows[index]
      let scaleFactor = shadow.length
      timeline.to(this.shadowTargets, 1, {
        scaleY: Math.abs(shadow.length),
        skewX: shadow.direction
      }, shadow.minute)
      index++
    }

    let timeScale = 5
    let repeats = 60 * timeScale
    let currentMinute = (Date.now() - this.beginningOfToday) / 60000
    timeline.timeScale(timeScale)
    timeline.repeat(repeats)
    timeline.seek(currentMinute)
    timeline.play()
  }

  _buildShadowsAnimation() {
    let shadows = []
    let minute = 0
    let minutesInADay = 1440
    while (minute < minutesInADay) {
      let date = new Date(this.beginningOfToday + minute * 60000)
      let sunPosition = this.sunPositionAt(date)
      let sunAltitude = sunPosition.altitude
      let sunAzimuth = sunPosition.azimuth
      let shadowLength = 1 / Math.tan(sunAltitude)
      shadows.push({
        date: date,
        minute: minute,
        length: shadowLength,
        direction: sunAzimuth * 180 / Math.PI
      })
      minute++
    }
    return shadows
  }

  setShadowImage() {
    var newShadowImage = null
    if (this.open) {
      newShadowImage = this.openImageTarget
    } else {
      newShadowImage = this.closedImageTarget
    }
    this.setCurrentShadowImage(newShadowImage)
  }

  setCurrentShadowImage(newShadowImage) {
    if (!newShadowImage.classList.contains("shadow__image--current")) {
      if (this.currentShadowImage) {
        this.currentShadowImage.classList.remove("shadow__image--current")
      }
      newShadowImage.classList.add("shadow__image--current")
    }
  }

  setPosition(x, y) {
    this.clipPath.attr('transform', `translate(${x},${y})`)
  }

  get currentShadowImage() {
    return this.imageTargets.filter(element => element.classList.contains("shadow__image--current")).first
  }

  get svg() {
    return SVG(this.svgTarget)
  }

  get clipPath() {
    return SVG(this.clipPathTarget)
  }

  get open() {
    let now = new Date()
    return now.getHours() > 10 && now.getHours() < 18
  }

  get splashBlockController() {
    return this.application.getControllerForElementAndIdentifier(this.splashBlock, "splash-block")
  }

  get splashBlock() {
    return this.element.closest(".block.splash_block")
  }

  get beginningOfToday() {
    let nowDate = new Date(Date.now())
    return nowDate.setHours(0, 0, 0, 0)
  }

  get latitude() {
    return 45.4408
  }

  get longitude() {
    return 12.3155
  }

  sunPositionAt(date) {
    return SunCalc.getPosition(date, this.latitude, this.longitude)
  }
}
