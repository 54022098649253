import { Controller } from "stimulus"
import { CheckoutEventBus } from "./events"

export default class extends Controller {
  static targets = ["form"]

  initialize() {
    CheckoutEventBus.on("handlePaymentSucces", (event) => {
      this.formTarget.submit()
    })
  }

  onFormSubmit(event) {
    event.preventDefault()
    CheckoutEventBus.emit("submittedPaymentMethodForm", event)
  }

  set loading(loading) {
    this.element.classList.toggle(this.data.get("loadingClass"), loading)
  }
}
