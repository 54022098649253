import { Controller } from "stimulus"
import { CartEventBus } from "./events"

export default class extends Controller {
  static targets = ["item", "itemsWrapper"]

  initialize() {
    CartEventBus.on("addItem", (event) => this.onAddItem(event) )
    CartEventBus.on("removeItem", (event) => this.onRemoveItem(event) )
  }

  onAddItem(event) {
    let [data, status, xhr] = event.detail
    let cartItem = new DOMParser().parseFromString(xhr.responseText, "text/html").body.firstChild
    if (!this.hasItemTarget) {
      this.itemsWrapperTarget.textContent = ""
    }

    if (document.getElementById(cartItem.id)) {
      let cartItemController = this.application.getControllerForElementAndIdentifier(document.getElementById(cartItem.id), "cart-item")
      cartItemController.onUpdateSuccess(event)
    } else {
      this.itemsWrapperTarget.appendChild(cartItem)
    }
  }

  onRemoveItem(event) {
    if (!this.hasItemTarget) {
      this.itemsWrapperTarget.textContent = "Your cart is empty"
    }
  }
}
